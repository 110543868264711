import React from "react";
import Collapsible from 'react-collapsible';

const style = {
    color: '#367fee'
};

const CollapsibleTag = (props) => (
    <Collapsible style={style} trigger={props.title}>
        {props.children}
    </Collapsible>
);

export default CollapsibleTag;