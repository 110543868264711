import React from 'react';
import './styles.css';

export default function Footer(){
    return(
        <footer>
           © Puntu - {new Date().getFullYear()}
        </footer>
    )
}

