import React from "react";
import styled from "react-emotion";

const Btn = styled('button')`
    background: #537ccd;
    color: #fff;
    padding: 6px 16px;
    font-size: 0.875rem;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    border: none;
    pointer-events: none;
`;

const ButtonTag = (props) => (
    <Btn>{props.text}</Btn>
);

export default ButtonTag;